import { AlertMessageDetails, AnswerDetails, QuestionState } from '../models/AppState';
import { QEAnswer, QEQuestion } from '../models/QEQuestion';
import { getPhotoCaptured } from './takePhotoHelper';

const addAnswerAndFilterBasedOnGroups = (previousSelectedAnswers: QEAnswer[], answer: QEAnswer) => {
  const nestedQuestionsToRemove: string[] = [];
  const siblingAnswersToRemove: string[] = [];
  const shouldKeepAnswer = (oldAnswer: QEAnswer, newAnswer: QEAnswer) => {
    let keepAnswer: boolean;
    const oldAnswerHasAnswerGroups = !!oldAnswer.answerGroups?.length;
    const newAnswerHasAnswerGroups = !!newAnswer.answerGroups?.length;
    if (oldAnswerHasAnswerGroups) {
      const answersShareAtLeast1Group = !!newAnswer.answerGroups?.some(g => oldAnswer.answerGroups?.includes(g));
      keepAnswer = answersShareAtLeast1Group;
    } else {
      if (newAnswerHasAnswerGroups) {
        keepAnswer = false;
      } else {
        keepAnswer = true;
      }
    }
    if (keepAnswer === false) {
      siblingAnswersToRemove.push(oldAnswer.value);
      if (oldAnswer.nestedQuestions) {
        nestedQuestionsToRemove.push(...oldAnswer.nestedQuestions);
      }
    }
    return keepAnswer;
  };
  return {
    newAnswers: previousSelectedAnswers.filter(a => shouldKeepAnswer(a, answer)).concat(answer),
    nestedQuestionsToRemove,
    siblingAnswersToRemove
  };
};

const addParentAnswers = (
  parentQuestion: QEQuestion,
  parentAnswer: QEAnswer,
  previouslySelectedParentAnswers: QEAnswer[]
) => {
  let newParentAnswers: QEAnswer[] = [];
  let parentSiblingsToRemove: string[] = [];
  let nestedQuestionsToRemove: string[] = [];

  if (parentQuestion.type === 'multi') {
    if (previouslySelectedParentAnswers.includes(parentAnswer)) {
      newParentAnswers = previouslySelectedParentAnswers;
    } else {
      ({
        newAnswers: newParentAnswers,
        nestedQuestionsToRemove,
        siblingAnswersToRemove: parentSiblingsToRemove
      } = addAnswerAndFilterBasedOnGroups(previouslySelectedParentAnswers, parentAnswer));
    }
  } else {
    parentSiblingsToRemove = previouslySelectedParentAnswers
      .filter(a => a.value !== parentAnswer.value)
      .map(a => {
        a.nestedQuestions && nestedQuestionsToRemove.push(...a.nestedQuestions);
        return a.value;
      });
    newParentAnswers = [parentAnswer];
  }
  return { newParentAnswers, nestedQuestionsToRemove, parentSiblingsToRemove };
};

export const removeAnswerAndParentAnswer = (
  answer: QEAnswer,
  previouslySelectedAnswers: QEAnswer[],
  parentAnswer?: QEAnswer,
  previouslySelectedParentAnswers?: QEAnswer[]
) => {
  let newParentAnswers: QEAnswer[] = [];
  const newAnswers = previouslySelectedAnswers.filter(a => a !== answer);

  if (parentAnswer && previouslySelectedParentAnswers) {
    // remove the parent answer if no sibling answers remain
    if (newAnswers.length === 0) {
      newParentAnswers = previouslySelectedParentAnswers.filter(a => a !== parentAnswer);
    } else {
      newParentAnswers = previouslySelectedParentAnswers;
    }
  }
  return { newAnswers, newParentAnswers };
};

const getPhotosToRemoveForQuestion = (
  worldStateQuestionResponses: QuestionState[],
  questionGuid: string,
  answersToRemove?: string[]
) => {
  const selectedPhotosToRemove: string[] = [];
  const existingResponsesForQuestion = worldStateQuestionResponses.find(r => r.guid === questionGuid)?.answers || [];
  const responsesToRemove = answersToRemove || existingResponsesForQuestion.map(resp => resp.value);
  responsesToRemove.forEach(ansValue => {
    const hasPhotoToRemove = existingResponsesForQuestion.find(response => response.value === ansValue)?.photoCaptured;
    if (hasPhotoToRemove) {
      selectedPhotosToRemove.push(ansValue);
    }
  });

  if (selectedPhotosToRemove.length > 0) {
    return {
      questionGuid: questionGuid,
      selectedAnswers: selectedPhotosToRemove
    };
  }
};

const getAllPhotosToRemove = (
  worldStateQuestionResponses: QuestionState[],
  siblingAnswersToRemove: string[],
  changedQuestion: QEQuestion,
  nestedQuestionsToRemove: string[],
  parentSiblingsToRemove?: string[],
  parentQuestion?: QEQuestion
) => {
  const photosToRemove: { questionGuid: string; selectedAnswers: string[] }[] = [];
  if (siblingAnswersToRemove.length > 0) {
    const siblingPhotosToRemove = getPhotosToRemoveForQuestion(
      worldStateQuestionResponses,
      changedQuestion.guid,
      siblingAnswersToRemove
    );
    siblingPhotosToRemove && photosToRemove.push(siblingPhotosToRemove);
  }

  if (parentQuestion && parentSiblingsToRemove && parentSiblingsToRemove.length > 0) {
    const parentPhotosToRemove = getPhotosToRemoveForQuestion(
      worldStateQuestionResponses,
      parentQuestion.guid,
      parentSiblingsToRemove
    );
    parentPhotosToRemove && photosToRemove.push(parentPhotosToRemove);
  }

  nestedQuestionsToRemove.forEach(guid => {
    const parentPhotosToRemove = getPhotosToRemoveForQuestion(worldStateQuestionResponses, guid);
    parentPhotosToRemove && photosToRemove.push(parentPhotosToRemove);
  });
  return photosToRemove;
};

export const handleAnswerChange = (
  worldStateQuestionResponses: QuestionState[],
  answer: QEAnswer,
  changedQuestion: QEQuestion,
  previousSelectedAnswers: QEAnswer[],
  parentAnswer?: QEAnswer,
  parentQuestion?: QEQuestion,
  sendAlertMessage?: (alertMessages: AlertMessageDetails) => void,
  previouslySelectedParentAnswers: QEAnswer[] = [],
  forceRemoveAnswer = false
) => {
  let newAnswers: QEAnswer[] = [];
  let newParentAnswers: QEAnswer[] = [];
  let nestedQuestionsToRemove: string[] = [];
  let parentSiblingsToRemove: string[] = [];
  const siblingAnswersToRemove: string[] = [];

  if (
    previousSelectedAnswers.includes(answer) &&
    (changedQuestion.type === 'multi' || parentQuestion || forceRemoveAnswer)
  ) {
    if (sendAlertMessage && getPhotoCaptured(worldStateQuestionResponses, changedQuestion, answer)) {
      sendAlertMessage({
        questionGuid: changedQuestion.guid,
        newAnswer: answer.value,
        selectedAnswers: previousSelectedAnswers.filter(ans => ans.value === answer.value).map(ans => ans.value),
        parentQuestion: parentQuestion?.guid,
        parentAnswer: parentAnswer?.value
      });
      return;
    }

    // Remove Answer (And Parent Answers if applicable)
    ({ newAnswers, newParentAnswers } = removeAnswerAndParentAnswer(
      answer,
      previousSelectedAnswers,
      parentAnswer,
      previouslySelectedParentAnswers
    ));
  } else {
    // Add Answer (And Parent Answers if applicable) + remove any disagreeing answers

    // if this is a nestedQuestion, we need to add Parent Answers
    if (parentQuestion && parentAnswer) {
      ({ newParentAnswers, nestedQuestionsToRemove, parentSiblingsToRemove } = addParentAnswers(
        parentQuestion,
        parentAnswer,
        previouslySelectedParentAnswers
      ));
    }

    if (changedQuestion.type === 'multi') {
      let moreNestedToRemove;
      let moreAnswersToRemove;

      ({
        newAnswers,
        nestedQuestionsToRemove: moreNestedToRemove,
        siblingAnswersToRemove: moreAnswersToRemove
      } = addAnswerAndFilterBasedOnGroups(previousSelectedAnswers, answer));
      nestedQuestionsToRemove.push(...moreNestedToRemove);
      siblingAnswersToRemove.push(...moreAnswersToRemove);
    } else {
      // single select case
      const siblingResponses = worldStateQuestionResponses.find(
        response => response.guid === changedQuestion.guid
      )?.answers;
      siblingResponses?.forEach(res => {
        siblingAnswersToRemove.push(res.value);
      });
      previousSelectedAnswers.forEach(ans => {
        // we need to remove any nestedAnswers from the previous answer
        ans.nestedQuestions && nestedQuestionsToRemove.push(...ans.nestedQuestions);
      });
      newAnswers = [answer];
    }

    if (sendAlertMessage) {
      const photosToRemove = getAllPhotosToRemove(
        worldStateQuestionResponses,
        siblingAnswersToRemove,
        changedQuestion,
        nestedQuestionsToRemove,
        parentSiblingsToRemove,
        parentQuestion
      );
      if (photosToRemove.length > 0) {
        sendAlertMessage({
          newAnswer: answer.value,
          questionGuid: changedQuestion.guid,
          photosToRemove: photosToRemove,
          parentQuestion: parentQuestion?.guid,
          parentAnswer: parentAnswer?.value
        });
        return;
      }
    }
  }

  const response = worldStateQuestionResponses.find(r => r.guid === changedQuestion.guid);
  const parentResponse = parentQuestion && worldStateQuestionResponses.find(r => r.guid === parentQuestion.guid);
  const newResponses = [
    ...worldStateQuestionResponses
      .filter(qr => !nestedQuestionsToRemove.includes(qr.guid))
      .filter(
        qr =>
          // remove responses for changed question and it's parent question so we can reapply with newly added answer
          qr.guid !== changedQuestion.guid && (parentAnswer && parentQuestion ? qr.guid !== parentQuestion.guid : true)
      ),
    ...(newParentAnswers.length && parentQuestion
      ? [
          {
            guid: parentQuestion.guid,
            answers: newParentAnswers.map(
              na => parentResponse?.answers.find(a => na.value === a.value) || { value: na.value }
            ),
            source: 'gfb_question'
          }
        ]
      : []),
    ...(newAnswers.length
      ? [
          {
            guid: changedQuestion.guid,
            answers: newAnswers.map(na => response?.answers.find(a => na.value === a.value) || { value: na.value }),
            source: 'gfb_question'
          }
        ]
      : [])
  ];
  return newResponses;
};

export const handleQuantityAnswerChange = (
  worldStateQuestionResponses: QuestionState[],
  answer: QEAnswer,
  quantity: number,
  changedQuestion: QEQuestion,
  previousSelectedAnswers: QEAnswer[],
  sendAlertMessage?: (alertMessages: AlertMessageDetails) => void
) => {
  let newResponse: AnswerDetails[] = [];
  let nestedQuestionsToRemove: string[] = [];
  let siblingAnswersToRemove: string[] = [];

  const existingResponsesForQuestion =
    worldStateQuestionResponses.find(r => r.guid === changedQuestion.guid)?.answers || [];
  const existingResponseForAnswer = existingResponsesForQuestion.find(a => a.value === answer.value);

  if (existingResponseForAnswer) {
    // changing qty for existing answer
    const siblingResponses =
      changedQuestion.type === 'multi'
        ? existingResponsesForQuestion.filter(response => response.value !== answer.value)
        : [];
    if (quantity > 0) {
      newResponse = [...siblingResponses, { ...existingResponseForAnswer, quantity }];
    } else {
      // quantity is now 0 and answer should be removed
      newResponse = [...siblingResponses];
    }
  } else if (changedQuestion.type === 'multi') {
    // adding answer to multi select
    let newAnswers: QEAnswer[] = [];

    ({ newAnswers, nestedQuestionsToRemove, siblingAnswersToRemove } = addAnswerAndFilterBasedOnGroups(
      previousSelectedAnswers,
      answer
    ));

    const siblingResponsesToKeep = newAnswers
      .filter(a => a.value !== answer.value)
      .map(na => existingResponsesForQuestion.find(a => na.value === a.value) || { value: na.value });
    newResponse = [
      ...siblingResponsesToKeep,
      {
        value: answer.value,
        quantity
      }
    ];
  } else {
    // single select case
    const siblingResponses = worldStateQuestionResponses.find(
      response => response.guid === changedQuestion.guid
    )?.answers;
    siblingResponses?.forEach(res => {
      siblingAnswersToRemove.push(res.value);
    });
    previousSelectedAnswers.forEach(ans => {
      // we need to remove any nestedAnswers from the previous answer
      ans.nestedQuestions && nestedQuestionsToRemove.push(...ans.nestedQuestions);
    });

    // adding answer to single select
    newResponse = [
      {
        value: answer.value,
        quantity
      }
    ];
  }

  if (sendAlertMessage) {
    const photosToRemove = getAllPhotosToRemove(
      worldStateQuestionResponses,
      siblingAnswersToRemove,
      changedQuestion,
      nestedQuestionsToRemove
    );
    if (photosToRemove.length) {
      sendAlertMessage({ questionGuid: changedQuestion.guid, newAnswer: answer.value, photosToRemove, quantity });
      return;
    }
  }

  const newResponsesWithoutChangedQuestion = worldStateQuestionResponses.filter(qr => qr.guid !== changedQuestion.guid);

  return newResponse.length
    ? [
        ...newResponsesWithoutChangedQuestion.filter(qr => !nestedQuestionsToRemove.includes(qr.guid)),
        {
          guid: changedQuestion.guid,
          answers: newResponse,
          source: 'gfb_question'
        }
      ]
    : newResponsesWithoutChangedQuestion;
};
