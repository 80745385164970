import React, { useState } from 'react';
import styled from 'styled-components';
import { AnswerButton } from '../../components/AnswerButton';
import { QEAnswer, QEQuestion } from '../../models/QEQuestion';
import { ChevronDown, ChevronUp } from './QuantityPicker';
import { getPhotoCaptured, getQuantity, showPhotoButton, handlePhotoButtonClick } from '../../helpers/takePhotoHelper';
import { TakePhotoDetails } from '../../models/AppState';
import { TakePhotos } from './TakePhotos';
import { AnswerLabel } from './AnswerLabel';
import { WorldState } from '../../helpers/computeWorldState';

export type Props = {
  worldState: WorldState;
  answer: QEAnswer;
  question: QEQuestion;
  nestedQuestion: QEQuestion;
  nestedResponses: QEAnswer[];
  onChange: (
    answer: QEAnswer,
    changedQuestion: QEQuestion,
    previousSelectedAnswers: QEAnswer[],
    parentAnswer?: QEAnswer,
    parentQuestion?: QEQuestion
  ) => void;
  disabled?: boolean;
  inlineImaging?: boolean;
  onPhotoButtonClick?: (takePhotos: TakePhotoDetails) => void;
};

export const NestedAnswers = ({
  worldState,
  answer,
  question,
  nestedQuestion,
  nestedResponses,
  onChange,
  disabled,
  inlineImaging,
  onPhotoButtonClick
}: Props) => {
  const [nestedExpanded, setNestedExpanded] = useState(false);
  const nestedQuestionResponses = nestedResponses;
  const selectedAndNestedAnswerSelected = nestedQuestionResponses.length > 0;
  return (
    <React.Fragment>
      <ButtonContainer className={selectedAndNestedAnswerSelected ? 'active' : ' '}>
        <AnswerButton
          key={answer.value}
          data-testid={`answer-${answer.value}`}
          className={selectedAndNestedAnswerSelected ? 'active nested' : 'nested'}
          disabled={disabled}
          onClick={() => {
            setNestedExpanded(!nestedExpanded);
          }}
        >
          {answer.value}
        </AnswerButton>
        {nestedExpanded || disabled ? (
          <ChevronUp
            data-testid="chevron-up"
            fill={selectedAndNestedAnswerSelected ? '#fff' : '#333'}
            marginRight={10}
            disabled={disabled}
            onClick={() => {
              setNestedExpanded(!nestedExpanded);
            }}
          />
        ) : (
          <ChevronDown
            data-testid="chevron-down"
            fill={selectedAndNestedAnswerSelected ? '#fff' : '#333'}
            marginRight={10}
            onClick={() => {
              setNestedExpanded(!nestedExpanded);
            }}
          />
        )}
      </ButtonContainer>

      {nestedExpanded || disabled ? (
        <NestedButtonContainer
          data-testid="nested-button-container"
          style={{
            borderTop: 'none'
          }}
          className={selectedAndNestedAnswerSelected ? 'active' : ' '}
        >
          {' '}
          <InnerNestedButtonContainer className={selectedAndNestedAnswerSelected ? 'active' : ' '} />
          <NestedQuestionText
            style={{ color: selectedAndNestedAnswerSelected ? '#fff' : '#333' }}
            data-testid="nested-question-text"
          >
            {nestedQuestion.text}
          </NestedQuestionText>
          {nestedQuestion.answers.map((nestedAnswer, index) => {
            const selectedNestedAnswer = nestedResponses.some(a => a.value === nestedAnswer.value);

            return (
              <TakePhotoContainer key={`nested-answer-${index}`}>
                <NestedAnswerButton
                  data-testid={`nested-answer-${index}`}
                  className={selectedNestedAnswer ? 'active' : ' '}
                  disabled={disabled}
                  onClick={() => {
                    onChange(nestedAnswer, nestedQuestion, nestedResponses, answer, question);
                  }}
                >
                  <AnswerLabel
                    value={nestedAnswer.value}
                    helpText={nestedAnswer.helpText}
                    photoCaptured={getPhotoCaptured(worldState.questionResponses, nestedQuestion, nestedAnswer)}
                  />
                </NestedAnswerButton>
                {showPhotoButton(worldState, nestedQuestion, nestedAnswer, inlineImaging) && (
                  <TakePhotos
                    count={getQuantity(worldState, nestedQuestion, nestedAnswer) || 1}
                    onClick={() => handlePhotoButtonClick(worldState, nestedQuestion, nestedAnswer, onPhotoButtonClick)}
                  />
                )}
              </TakePhotoContainer>
            );
          })}
        </NestedButtonContainer>
      ) : null}
    </React.Fragment>
  );
};
const InnerNestedButtonContainer = styled.div`
  margin: 0px 15px 0px 15px;
  border-top: 1px dashed black;
  &.active {
    border-top: 1px dashed white;
  }
`;
const NestedButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.inactiveButtonBackground};
  padding: 5px 0 10px 0;

  &.active {
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
  }
`;

export const NestedAnswerButton = styled(AnswerButton)`
  margin: 5px 10px 0px 10px;
  background-color: ${({ theme }) => theme.color.inactiveButtonBackground};
  color: ${({ theme }) => theme.color.inactiveButtonText};
  border: 1px solid ${({ theme }) => theme.color.inactiveButtonBorder};
  border-radius: ${({ theme }) => theme.radius.lg};

  &.active {
    color: ${({ theme }) => theme.color.nestedAnswerButtonText};
    background-color: ${({ theme }) => theme.color.nestedAnswerButtonBackground};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.inactiveButtonBackground};
  align-items: center;
  justify-content: center;
  border: none;
  flex-grow: 1;
  padding-right: 50;

  &.active {
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
  }
`;

const TakePhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NestedQuestionText = styled.p`
  padding-top: ${({ theme }) => theme.space.sm};
  margin: 0;
  font-size: ${({ theme }) => theme.typography.size.sm};
  text-align: center;
`;
